import React, { useEffect, useLayoutEffect, useState, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import ChatInput from "./ChatInput";
import useFocus from "../HooksUtil/useFocus";
import ChatConversation from "./ChatConversation";
import ChipMenu from "./ChipMenu";
import {
    alphabetSort,
    displayChipOptions,
} from "../../utils/utils";
import {
    disableChat,
    getBaseBotPayload,
    getBaseUserPayload,
    getBotErrorMessagePayload,
    getBotFeedbackMessagePayload,
    getLoadingMsgBotPayload,
    getBotBackendPayload,
    getFallbackResp,
    getBotStartFeedbackPayload
} from "../common/PayloadProvider";
import TopicPage from "../TopicPage/TopicPage";
import useCreateChatItem from "../HooksUtil/useCreateChatItem"
import useQnaSearch from "../HooksUtil/useQnaSearch";
import useOscIncident from "../HooksUtil/useOscIncident";
import useCheckForHuman from "../HooksUtil/useCheckForHuman";
import { useTranslation } from "react-i18next";
// istanbul ignore next
const useStyles = makeStyles((theme) => ({
    chatPage: {
        height: "100%",
        padding: 0,
        width: "calc(100% - 500px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        [theme.breakpoints.down("xs")]: {
            width: "calc(100% - 50px)",
        },
        [theme.breakpoints.down("s")]: {
            width: "calc(100% - 50px)",
        },
        [theme.breakpoints.down("md")]: {
            width: "calc(100% - 50px)",
        },
    },
    chatConversationContainer: {
        width: "100%",
        overflow: "auto",
        paddingRight: "10px"
    },
    messageContainer: {
        // height: "100%",
        // paddingBottom: "15px"
    },
}));

const ChatWindow = (props) => {
    const {
        convId,
        isRRPermission,
        userInfo,
        chatStorageId,
        chatBotAppConfig,
        language,
    } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const { saveChatItem , getChatItem } = useCreateChatItem();
    const { createOscIncident } = useOscIncident();
    const { qnaSearch } = useQnaSearch();
    const { checkForHuman } = useCheckForHuman();
    const [inputRef, setInputFocus] = useFocus();
    const [showNps, setShowNps] = useState(false);
    const [chatModel, setChatModel] = useState([]);
    const [inputType, setInputType] = useState("text");
    const [isUserInputDisabled, setIsUserInputDisabled] = useState(false);
    const [isUserInputVisible, setIsUserInputVisible] = useState(true);
    const [userInputString, setUserInputString] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [selectOptions, setSelectOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState({});
    const [lastConversation, setLastConversation] = useState({});
    const [levels, setLevels] = useState([]);
    const [topics, setTopics] = useState({});
    const [showTopic, setshowTopic] = React.useState(false);
    const [selectedSubtopic, setselectedSubtopic] = React.useState(null);
    const [noIntentCounter,setnoIntentCounter]  = React.useState(0);
    const [npsShowCount,setnpsShowCount]  = React.useState(true);
    const url = (window.location != window.parent.location)? document.referrer: document.location.href;
    const isPortal = /\.sustainabilityportal\./;
    const ishub = /\.www/ && /\.walmartsustainabilityhub\./;
    const channel= (isPortal.exec(url))?"portal":(ishub.exec(url))?"hub":"other";
   
    const messageEndRef = useRef(null); 
    
    const menuOption = (chatOptions) => {
        if (chatOptions?.options?.length) {
            const checkLevels = chatOptions.options
                ?.filter(row => row.level)
                .map(row => row.level);

            if (checkLevels.length && checkLevels[0] === 1) {
                if (userInfo.isManager) {
                    setLevels(chatOptions.options);
                    return chatOptions.options.filter(row => row.level === 1);
                } else if (userInfo.isDelegate) {
                    return alphabetSort(chatOptions.options, "option");
                }
            } else if (checkLevels.length) {
                return alphabetSort(chatOptions.options, "option");
            }
        }

        return null;
    };
   
    const postUserResponse = (payload) => {
        
        payload.createdTs = new Date().getTime();
        payload.channel = channel;
        setShowLoader(true);
        setIsUserInputDisabled(true);
        const backend_payload = getBotBackendPayload(payload.text, convId , noIntentCounter);
        if (chatBotAppConfig?.EnableQNA) {
            qnaSearch({ question: payload.text }, false, language)
              .then((res) => {
                setShowLoader(false);
                setIsUserInputDisabled(false);
                const chatModelResp = [];
                const createdTime = new Date().getTime();
                const { context = null, semantic_similarity = 0 } =
                  res.body?.contexts?.at(0) || {};
                const aaproximateSemanticSimilarity = Math.round(
                  semantic_similarity * 100
                );
                if (
                  aaproximateSemanticSimilarity >
                  chatBotAppConfig?.QnaSemanticSimilarityThreshold
                ) {
                    res.body?.answer.map((response) => {
                    const botpayloadText = getBaseBotPayload();
                    botpayloadText.text = response;
                    botpayloadText.createdTs = createdTime;
                    botpayloadText.channel = channel;
                    chatModelResp.push(botpayloadText);
                  });
                } else {
                  const botpayloadText = getBaseBotPayload();
                  botpayloadText.text = t(
                    "CHATBOT_COMMONS.LOW_CONFIDENCE_MESSAGE"
                  );
                  botpayloadText.createdTs = createdTime;
                  botpayloadText.channel = channel;
                  chatModelResp.push(botpayloadText);
                }
                setLastConversation({
                    inputQueryPhrase: payload.text,
                    botResponse: res.body?.answer?.at(0),
                });
                chatModelResp[chatModelResp.length - 1].qnaResponse =
                  res.body?.answer?.at(0);
                chatModelResp[chatModelResp.length - 1].qnaLatency = res.qnaLatency;
                chatModelResp[chatModelResp.length - 1].qnaContext = context;
                chatModelResp[chatModelResp.length - 1].qnaSemanticSimilarity =
                  aaproximateSemanticSimilarity;
                chatModelResp.push(getBotFeedbackMessagePayload(false));
                setChatModel((chatModel) => [...chatModel, ...chatModelResp]);
                const converseFetchTimeStamp = new Date().getTime();
                fetch("/api/talk", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(backend_payload),
                })
                  .then((response) => response.json())
                  .then((data) => {
                    chatModelResp[chatModelResp.length - 2].converseIntentName =
                      data.intentName;
                    chatModelResp[chatModelResp.length - 2].converseResponse =
                      data.rawResponse?.text;
                    chatModelResp[chatModelResp.length - 2].converseLatency =
                      new Date().getTime() - converseFetchTimeStamp;
                    saveChatItem(
                      [payload, ...chatModelResp],
                      convId,
                      chatStorageId
                    );
                  })
                  .catch((err) => {
                    saveChatItem(
                      [payload, ...chatModelResp],
                      convId,
                      chatStorageId
                    );
                  });
              })
              .catch((error) => {
                console.log("Error QNA-002", error);
                setShowLoader(false);
                setIsUserInputDisabled(false);
                setLastConversation({
                  inputQueryPhrase: payload.text,
                  botResponse: "QNA Request Failed"
                });
                const errorMsgBubble = getBotErrorMessagePayload();
                const createdTime = new Date().getTime();
                errorMsgBubble.createdTs = createdTime;
                setChatModel((chatModel) => [
                  ...chatModel,
                  errorMsgBubble,
                  getBotFeedbackMessagePayload(false),
                ]);
                saveChatItem([errorMsgBubble], convId, chatStorageId);
              });
        }
        else {
        fetch("/api/talk", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(backend_payload),
        })
            .then(response => response.json())
            .then(data => {
                console.log("data", JSON.stringify(data));
                const latency = new Date().getTime() - payload.createdTs;
                // setconversePrevResponse(data)
                setShowLoader(false);
                setIsUserInputDisabled(false);
                
                const menuData = menuOption(data);
                if (menuData) {
                    data.options = menuData;
                }
                const chatModelResp = [];
                if(data?.rawResponse?.noIntentCounter>=0){
                    setnoIntentCounter(data.rawResponse.noIntentCounter)
                }
                const createdTime = new Date().getTime();
                
                data.rawResponse?.text.map((response) => {
                    const botpayloadText = getBaseBotPayload();
                    botpayloadText.text = response;
                    botpayloadText.createdTs = createdTime;
                    botpayloadText.channel = channel;
                    chatModelResp.push(botpayloadText);
                });
                chatModelResp[chatModelResp.length - 1].subtext = data.rawResponse?.subtext;
                chatModelResp[chatModelResp.length - 1].options = data.rawResponse?.options;
                chatModelResp[chatModelResp.length - 1].converseLatency = latency;
                payload= { ...payload, intentName: data?.intentName }
                qnaSearch({ question: payload.text }, false, language)
                  .then((res) => {
                    chatModelResp[chatModelResp.length - 1].qnaResponse =
                      res.body.answer;
                    chatModelResp[chatModelResp.length - 1].qnaLatency =
                      res.qnaLatency;
                    saveChatItem(
                      [payload, ...chatModelResp],
                      convId,
                      chatStorageId
                    );
                  })
                  .catch((err) => {
                    saveChatItem(
                      [payload, ...chatModelResp],
                      convId,
                      chatStorageId
                    );
                  });
                // if(data)
                // const count = npsCounter+1
               
                
                if(data.rawResponse.isAnswer && data.intentName !=="no_intent" && npsShowCount){
                    setnpsShowCount(false)

                    fetch("/api/chathistory/showNpsPrompt", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ "localStorageId": chatStorageId}),
                    })
                        .then(response => response.json())
                        .then(respData => {
                            console.log(respData)
                            if(respData){
                                setShowNps(true)
                                chatModelResp.push(getBotFeedbackMessagePayload(true))
                                setChatModel(chatModel => [...chatModel, ...chatModelResp]);

                            }else{
                                setChatModel(chatModel => [...chatModel, ...chatModelResp]);
                            }
                        })
                }
                else {
                    setChatModel(chatModel => [...chatModel, ...chatModelResp]);
                }
            })
            .catch(error => {
                console.log("Error fetch-002", error);
                setShowLoader(false);
                setIsUserInputDisabled(false);

                const errorMsgBubble = getBotErrorMessagePayload();
                const createdTime = new Date().getTime();
                errorMsgBubble.createdTs = createdTime;

                setChatModel(chatModel => [...chatModel, errorMsgBubble]);
                saveChatItem([errorMsgBubble], convId, chatStorageId)

            });
        }
    };

    const handleFormSubmit = (event, optionString, option) => {
        event?.preventDefault();
        if (!optionString && userInputString.trim() === "") {
            return;
        }
        const userIntent = optionString ? optionString : userInputString;
        const payload = getBaseUserPayload(userIntent, userInfo);
        const subTopicSelected = Object.keys(topics?.["all_topics"]).includes(userIntent);
        
        if (
          userIntent?.toLowerCase() == "menu" ||
          userIntent == "CHATBOT_COMMONS.MENU_TEXT"
        ) {
            handleMenuMessage();
        } else if (
          userIntent == "I am all set" ||
          userIntent == "CHATBOT_COMMONS.I_AM_ALL_SET_TEXT"
        ) {
            setChatModel(chatModel => [...chatModel, getBotFeedbackMessagePayload(false)]);
        } else if (userIntent == "ALL_TOPICS") {
            setshowTopic(true);
        } else if (subTopicSelected) {
            setshowTopic(true);
            setselectedSubtopic(userIntent);
        } else {
            setChatModel((chatModel) => [
              ...chatModel,
              {
                ...payload,
                text: subTopicSelected ? t(payload.text) : payload.text,
              },
            ]);
            postUserResponse(payload);
        }
        setUserInputString("");
        setInputType("text");
        scrollToBottom();
    };
    const handleFetchOptions = (event) => {
        fetch("/api/topics", {
            method: "GET"
        })
            .then(response => response.json())
            .then(data => {
                // console.log("data", JSON.stringify(data));
                setTopics(data);
            })
            .catch(error => {
                console.log("Error fetch-002", error);
            });
    };
    useEffect(() => {
        const latestRes = chatModel[chatModel.length - 1];
        if (latestRes && latestRes.sender === "bot" && latestRes?.inputDataType?.toLowerCase() !== "loader") {
            if (!latestRes.inputDataType) {
                setInputType("text");
            } else if (latestRes.inputDataType.toLowerCase() === "autocomplete") {
                setSelectOptions(latestRes.options);
                setInputType("autocomplete-sync");
            }
        }
        setInputFocus();
        scrollToBottom();
    }, [chatModel]);
    
    const resetLastConversation = (messageArray) => {
        if (messageArray?.length > 0) {
            for (let i = messageArray?.length - 1; i >= 1; i--) {
                if (
                messageArray[i]?.sender === "bot" &&
                messageArray[i]?.receiver === "user" &&
                messageArray[i]?.text &&
                messageArray[i]?.qnaResponse &&
                messageArray[i]?.qnaLatency &&
                messageArray[i]?.qnaContext &&
                messageArray[i]?.qnaSemanticSimilarity &&
                messageArray[i]?.converseResponse &&
                messageArray[i]?.converseIntentName &&
                messageArray[i]?.converseLatency &&
                messageArray[i - 1]?.sender === "user" &&
                messageArray[i - 1]?.receiver === "bot" &&
                messageArray[i - 1]?.text &&
                messageArray[i]?.convId === messageArray[i - 1]?.convId
                ) {
                    setLastConversation({
                        inputQueryPhrase: messageArray[i - 1]?.text,
                        botResponse: messageArray[i]?.text,
                    });
                    break;
                }
            }
        }
    };

    const handleWelcomeMessage = () => {
        setShowLoader(true);
        setIsUserInputDisabled(true);
        const backend_payload = getBotBackendPayload("Hi", convId, noIntentCounter);
        fetch("/api/welcome/user", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(backend_payload),
        })
            .then(response => response.json())
            .then(data => {
                setShowLoader(false);
                setIsUserInputDisabled(false);
                const botpayload = getBaseBotPayload();
                botpayload.text = t(data.rawResponse?.text[0]);
                botpayload.subtext = data.rawResponse?.subtext;
                botpayload.options = data.rawResponse?.options;
                setChatModel(chatModel => [...chatModel, botpayload]);
                botpayload.intentName = data?.intentName
                // saveChatItem([botpayload], convId, chatStorageId)
            })
            .catch(error => {
                console.log("Error fetch-002", error);
                setShowLoader(false);
                setIsUserInputDisabled(false);

                const errorMsgBubble = getBotErrorMessagePayload();
                setChatModel(chatModel => [...chatModel, errorMsgBubble]);
            });
    };
    useEffect(() => {
        if (Object.keys(topics).length == 0 && props.convId!="") {
            getChatItem(chatStorageId).then(res => 
                {
                    setChatModel(res)
                    resetLastConversation(res);
                    handleWelcomeMessage()
                }
                ).catch((err)=>{
                    handleWelcomeMessage()
                })
            handleFetchOptions();
            const payload = {
                "event":"bot-startup",
                "channel": channel,
                "createdTs": new Date().getTime()
            }
            console.log("save chat ",payload, props.convId)
            saveChatItem([payload], props.convId, chatStorageId)
        }
    }, [props.convId]);
    const scrollToBottom = () => {
        messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleFormSubmit(event);
        }
    };
    useLayoutEffect(() => {
        scrollToBottom();
    }, [chatModel]);
    const addItemsToChatModelAndSaveChatHistory = (payload) => {
        setChatModel([...chatModel, payload]);
        setShowNps(false)
    };
    const handleCancel = () => {

    };
    useEffect(() => {
        if (showLoader) {
            setChatModel(chatModel => [...chatModel, getLoadingMsgBotPayload()]);
        } else {
            setChatModel(chatModel.filter(c => c.inputDataType !== "loader"));
        }
    }, [showLoader]);
    const handleMenuMessage = () => {
        setShowLoader(true);
        setIsUserInputDisabled(true);
        fetch("/api/menu", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
        })
            .then(response => response.json())
            .then(data => {
                console.log("Menu data",data);

                setShowLoader(false);
                setIsUserInputDisabled(false);
                const botpayload = getBaseBotPayload();
                botpayload.text = t(data.rawResponse?.text[0]);
                botpayload.subtext = data.rawResponse?.subtext;
                botpayload.options = data.rawResponse?.options;
                setChatModel(chatModel => [...chatModel, botpayload]);
            })
            .catch(error => {
                console.log("Error fetch-002", error);
                setShowLoader(false);
                setIsUserInputDisabled(false);

                const errorMsgBubble = getBotErrorMessagePayload();
                setChatModel(chatModel => [...chatModel, errorMsgBubble]);
            });
    };
    const handleClickOptions = (e, question = null) => {
        setshowTopic((showTopic) => !showTopic);
        setselectedSubtopic(null);
        if (question != "") {
            handleFormSubmit(e, question);
        } else if (question == "") {
            handleMenuMessage();
        }
    };
    const formatCreateIncidentErrorMessage = (errMessage, error) => {
        if(errMessage) {
            console.error(errMessage);
        }
        if(error){
            console.error(error);
        }
        setShowLoader(false);
        setIsUserInputDisabled(false);
        const errorMsgBubble = getBotErrorMessagePayload();
        setChatModel((chatModel) => [
            ...chatModel,
            errorMsgBubble,
        ]);
    }
    const handleCreateIncident = (createIncidentPayload, captchaToken, closeModal, setIsIncidentCreated) => {
        setShowLoader(true);
        setIsUserInputDisabled(true);
        closeModal();
        if (chatBotAppConfig?.EnableGoogleCaptcha) {
            if (captchaToken) {
              checkForHuman(
                captchaToken,
                chatBotAppConfig?.CaptchaVerificationThreshold
              )
                .then((res) => {
                  if (res.success) {
                    createIncident(createIncidentPayload, setIsIncidentCreated);
                  } else {
                    formatCreateIncidentErrorMessage(
                      "Error CAPTCHA-SUSPECTS-BOT-002",
                      null
                    );
                  }
                })
                .catch((error) => {
                  formatCreateIncidentErrorMessage(
                    "Error CAPTCHA-SUSPECTS-BOT-001",
                    error
                  );
                });
            } else {
              formatCreateIncidentErrorMessage(
                "Error InvalidIncidentPayload-001 | Error InvalidReCaptchaToken-001",
                null
              );
            }
        } else {
          createIncident(createIncidentPayload, setIsIncidentCreated);
        }
    }

    const createIncident = (createIncidentPayload, setIsIncidentCreated) => {
        createOscIncident({
          ...createIncidentPayload,
          vendorQueryForDislikedResponse: lastConversation.inputQueryPhrase,
          dislikedBotResponse: lastConversation.botResponse,
        }).then((res) => {
            setIsIncidentCreated(true);
            const data = res?.data;
            setShowLoader(false);
            setIsUserInputDisabled(false);
            const botpayload = getBotStartFeedbackPayload();
            botpayload.text =
              t("INCIDENT_MGMT.INCIDENT_CREATED_MESSAGE") + data?.lookupName;
            setChatModel((chatModel) => [...chatModel, botpayload]);
            qnaSearch(
                {
                id: data?.id,
                lookupName: data?.lookupName,
                question: createIncidentPayload?.queryText,
                },
                true,
                language,
            );
            })
            .catch((error) => {
            formatCreateIncidentErrorMessage(
                "Error CreateIncident-001",
                error
            );
        });
    }
    const renderChat = () => {
        return (
            <>
                {showTopic && Object.keys(topics).length > 0 ?
                    <TopicPage showTopic={showTopic} topics={topics} handleClickOptions={handleClickOptions} selectedSubtopic={selectedSubtopic} />
                    :
                    <>
                        <div className={classes.chatConversationContainer}>

                            <div className={classes.messageContainer}>
                                <ChatConversation
                                    addChatPayload={addItemsToChatModelAndSaveChatHistory}
                                    chatModel={chatModel}
                                    handleFormSubmit={handleFormSubmit}
                                    handleKeyPress={handleKeyPress}
                                    handleCancel={handleCancel}
                                    setInputFocus={setInputFocus}
                                    setIsUserInputDisabled={setIsUserInputDisabled}
                                    setUserInputString={setUserInputString}
                                    showFileUploadComponent={inputType === "file"}
                                    scrollToBottom={scrollToBottom}
                                    showLoader={showLoader}
                                    userInfo={userInfo}
                                    userInputString={userInputString}
                                    chatStorageId={chatStorageId}
                                    showNpsFlag={showNps}
                                    handleMenuMessage={handleMenuMessage}
                                    channel={channel}
                                    chatBotAppConfig={chatBotAppConfig}
                                    lastConversation={lastConversation}
                                    handleCreateIncident={handleCreateIncident}
                                />
                                {displayChipOptions(chatModel[chatModel.length - 1]) &&
                                    <ChipMenu
                                        chatModel={chatModel}
                                        handleFormSubmit={handleFormSubmit}
                                        isRRPermission={isRRPermission}
                                        levels={levels}
                                        // selectedFiles={selectedFiles}
                                        setChatModel={setChatModel}
                                        userInfo={userInfo}
                                    />
                                }
                            </div>
                            <div ref={messageEndRef} />
                        </div>
                        <ChatInput
                            currentChatItem={chatModel[chatModel.length - 1]}
                            inputType={inputType}
                            isUserInputDisabled={isUserInputDisabled}
                            isUserInputVisible={isUserInputVisible}
                            ref={inputRef}
                            handleKeyPress={handleKeyPress}
                            setInputFocus={setInputFocus}
                            setUserInputString={setUserInputString}
                            userInputString={userInputString}
                            selectedOption={selectedOption}
                            selectOptions={selectOptions}
                            setSelectedOption={setSelectedOption}
                            setSelectOptions={setSelectOptions}
                            handleClickOptions={handleClickOptions}
                            handleFormSubmit={handleFormSubmit}
                        />
                    </>
                }
            </>
        );
    };

    return (
        <div className={classes.chatPage}>
            {renderChat()}
        </div>
    );
};

ChatWindow.propTypes = {
    convId: PropTypes.string,
    isRRPermission: PropTypes.bool,
    userInfo: PropTypes.object.isRequired,
};

export default ChatWindow;
